import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { CategoryData, CategoryResultInterface } from "../interfaces/category-result.interface";
import { CategorySingleResultInterface } from "../interfaces/category-single-result.interface";

export interface GetAllCategoryParam {
  page?: number,
  limit?: number,
}

export const GetAllCategory = async (params: GetAllCategoryParam): Promise<CategoryResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/categories',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: CategoryResultInterface = a.response?.data;
    return response;
  }
}

export const ShowCategory = async (categoryId: string): Promise<CategorySingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/categories/' + categoryId,
    );
    return response.data as CategorySingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: CategorySingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitCategory = async (form: CategoryData): Promise<CategorySingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/categories',
      form
    );
    return response.data as CategorySingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: CategorySingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateCategory = async (id: string, form: CategoryData): Promise<CategorySingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/categories/' + id,
      form
    );
    return response.data as CategorySingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: CategorySingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteCategory = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/categories/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}