import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { GensetGridData, GensetGridResultInterface } from '../interfaces/genset-grid-result.interface';
import { GensetGridSingleResultInterface } from '../interfaces/genset-grid-single-result.interface';
import { DeleteGensetGrid, GetAllGensetGrid, GetAllGensetGridParam, ShowGensetGrid, SubmitGensetGrid, UpdateGensetGrid } from '../providers/genset-grid.provider';

interface GensetGridState {
  gensetGridModel?: GensetGridResultInterface;
  gensetGrids: GensetGridData[];
  gensetGridsSelectData: SelectData[];
  gensetGridLoading: boolean;
  onDeleteGensetGrid: (id: string, params: GetAllGensetGridParam) => void;
  getGensetGrids: (params: GetAllGensetGridParam) => void;
}

export const useGensetGridStore = create<GensetGridState>((set, get) => ({
  gensetGridLoading: false,
  gensetGridModel: undefined,
  gensetGrids: [],
  gensetGridsSelectData: [],
  getGensetGrids: async (params: GetAllGensetGridParam) => {
    set({ gensetGridLoading: true });
    const response = await GetAllGensetGrid(params);
    if (response) {
      if (response.status) {
        set({ gensetGridModel: response });
        if (response.data.data.length > 0) {
          let transformGensetGrids: GensetGridData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformGensetGrids.push({
              id: item.id,
              name: item.name,
              media: item.media,
              sort: item.sort
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ gensetGrids: transformGensetGrids, gensetGridsSelectData: transformSelectDatas });
        }
      }
    }
    set({ gensetGridLoading: false });
  },
  onDeleteGensetGrid: async (id: string, params: GetAllGensetGridParam) => {
    const response = await DeleteGensetGrid(id);
    if (response.status) {
      get().getGensetGrids(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface GensetGridFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: GensetGridSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useGensetGridFormStore = create<GensetGridFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowGensetGrid(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: GensetGridData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowGensetGrid(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Media',
        placeholder: 'Media...',
        type: 'file-upload-v2',
        name: 'media',
        value: data?.media ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Sort',
        placeholder: 'Sort...',
        type: 'text',
        name: 'sort',
        value: data?.sort ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: GensetGridSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitGensetGrid(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateGensetGrid(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
