import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { FeedbackData, FeedbackResultInterface } from "../interfaces/feedback-result.interface";
import { FeedbackSingleResultInterface } from "../interfaces/feedback-single-result.interface";

export interface GetAllFeedbackParam {
  page?: number,
  limit?: number,
}

export const GetAllFeedback = async (params: GetAllFeedbackParam): Promise<FeedbackResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/feedbacks',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: FeedbackResultInterface = a.response?.data;
    return response;
  }
}

export const ShowFeedback = async (feedbackId: string): Promise<FeedbackSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/feedbacks/' + feedbackId,
    );
    return response.data as FeedbackSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FeedbackSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitFeedback = async (form: FeedbackData): Promise<FeedbackSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/feedbacks',
      form
    );
    return response.data as FeedbackSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FeedbackSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateFeedback = async (id: string, form: FeedbackData): Promise<FeedbackSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/feedbacks/' + id,
      form
    );
    return response.data as FeedbackSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FeedbackSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteFeedback = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/feedbacks/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}