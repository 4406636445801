import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Outlet, useNavigate } from "react-router-dom";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import { FormAction, FormProps } from "../../../interfaces/form-action-interfaces";
import { useBrandStore } from "../../brand/states/brand.state";
import { Card, CardHeader, CardBody, CardFooter } from "../../forms/components/card";
import FormsComponent from "../../forms/components/forms.component";
import { PageTitle } from "../../forms/components/page-title";
import { Table } from "../../forms/components/table";
import { ProductData } from "../interfaces/product-result.interface";
import { GetAllProductParam } from "../providers/product.provider";
import { useProductStore } from "../states/product.state";

export const ProductLayout = () => {
  return (
    <>
      <PageTitle
        title={'Product'}
        subtitle={'This is an example dashboard created using build-in elements and components.'}
      />
      <Outlet/>
    </>
  );
}

export const ProductScreen = () => {

  const { setProductParams, productParams, searchForms, setSearchForms, products, getProducts, productModel, onDeleteProduct, setSearchFormSelectData } = useProductStore();
  const { getBrands, brandsSelectData } = useBrandStore();
  const navigate = useNavigate();

  useEffect(() => {
    getBrands({ page: 0, limit: 1000 });
  }, [])

  useEffect(() => {
    getProducts(productParams);
  }, [productParams])

  useEffect(() => {
    if (brandsSelectData.length > 0) {
      setSearchFormSelectData(1, brandsSelectData);
    }
  }, [brandsSelectData]);

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected + 1;
    setProductParams({ 
      ...productParams,
      page: selectedPage
    })
  }

  const onSearch = () => {
    let formData = {}
    for (const v of searchForms) {
      formData = {
        ...formData,
        [v.name]: generateFormLogic(v.type, v?.value ?? '')
      }
    }

    setProductParams({
      ...productParams,
      ...formData,
    });
  }

  const handleOnEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: ProductData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.UPDATE,
        title: 'Product',
        subtitle: 'Form Product',
        id: item.id,
      } as FormProps
    });
  }

  const handleOnView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: ProductData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.VIEW,
        title: 'Product',
        subtitle: 'Form Product',
        id: item.id
      } as FormProps
    });
  }

  return (
    <>
      <Card>
        <CardHeader title={"Product Search"} />
        <CardBody>
          <FormsComponent
            forms={searchForms}
            setForms={setSearchForms}
          />
        </CardBody>
        <CardFooter>
          <button 
            type="submit" 
            className="btn btn-success btn-lg"
            onClick={(e) => {
              e.preventDefault();
              onSearch();
            }}>
              Search
          </button>
        </CardFooter>
      </Card>
      <Card>
        <CardHeader title={'Product'}>
          <div className="btn-actions-pane-right">
            <button 
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                navigate('form', {
                  state: {
                    action: FormAction.CREATE,
                    title: 'Product',
                    subtitle: 'Form Product'
                  } as FormProps
                });
              }}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </CardHeader>
        <CardBody clean={true}>
          <Table
            data={products}
            actionButton={(index, item) => {
              let data: ProductData = item as ProductData;
              return (
                <div role={'group'} className="btn-group-sm btn-group btn-group-toggle">
                  {
                    <>
                      <button type="button" className="btn btn-primary" onClick={(e) => handleOnView(e, data)}>View</button>
                      <button type="button" className="btn btn-danger" onClick={e => onDeleteProduct(data.id, productParams)}>Delete</button>
                      <button type="button" className="btn btn-warning" onClick={e => handleOnEdit(e, data)}>Edit</button>
                    </>
                  }
                </div>
              );
            }}
          />
        </CardBody>
        <CardFooter>
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={productModel?.data?.meta?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </CardFooter>
      </Card>
    </>
  );
}