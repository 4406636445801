import React, { useContext } from "react";
import { SidebarContext, SidebarContextType } from "../../../contexts/sidebar/sidebar-context";
import { useSidebarStore } from "../../../contexts/sidebar/sidebar.state";
import { Header } from "./header";
import { Sidebar } from "./sidebar";

interface ContainerProps {
  children?: React.ReactNode
}

export const Container = ({ children }: ContainerProps) => {

  const { isSidebarMobileOpen, isSidebarDesktopOpen } = useSidebarStore()

  return (
    <div 
      className={`app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar${isSidebarDesktopOpen ? 'closed-sidebar' : ''} ${isSidebarMobileOpen ? 'closed-sidebar-mobile closed-sidebar sidebar-mobile-open' : ''}`}>
      <Header/>

      <div className="app-main">
        <Sidebar/>
        <div className="app-main__outer">
          <div className="app-main__inner">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}