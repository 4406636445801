import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosInterceptorSetup } from "../../../api/api";
import { useAuthStore } from "../states/auth.state";

const AxiosInterceptorNavigate = () => {
  const navigate = useNavigate();
  const uas = useAuthStore();
  useEffect(() => {
    AxiosInterceptorSetup(navigate, uas);
  }, []);
  return <></>;
}

export default AxiosInterceptorNavigate;