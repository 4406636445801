import { FormAction } from "../../interfaces/form-action-interfaces";
import { ISidebarInterface } from "../../interfaces/sidebar-interfaces";
import { DASHBOARD, USER, ROLE, BRAND, CATEGORY, SITE, PRODUCT, BANNER, PARTNER, ROOT, FEEDBACK, ENGINE_TYPE, GENSET_GRID, TRUCK_GRID } from "../../router/routes";

export const sideBarItem: ISidebarInterface[] = [
  {
    parent: "Dashboard",
    link: "",
    childs: [
      {
        parent: "Analytic",
        link: ROOT
      }
    ]
  },
  {
    parent: "User",
    link: "",
    childs: [
      {
        parent: "User",
        link: USER
      },
      {
        parent: "Role",
        link: ROLE
      },
    ]
  },
  {
    parent: "Component",
    link: "",
    childs: [
      // {
      //   parent: "Sites",
      //   link: SITES_FORM,
      //   state: {
      //     action: FormAction.UPDATE,
      //     title: 'Sites',
      //     subtitle: 'Sites',
      //     id: ''
      //   }
      // },
      // {
      //   parent: "Homepage",
      //   link: HOMEPAGE_FORM,
      //   state: {
      //     action: FormAction.UPDATE,
      //     title: 'Homepage',
      //     subtitle: 'Homepage',
      //     id: '82a4242a-d1c0-424c-96ae-61deba3572d9'
      //   }
      // },
      {
        parent: "Site",
        link: SITE
      },
      {
        parent: "Brand",
        link: BRAND
      },
      {
        parent: "Category",
        link: CATEGORY
      },
      {
        parent: "Product",
        link: PRODUCT
      },
      {
        parent: "Engine Type",
        link: ENGINE_TYPE
      },
      {
        parent: "Banner",
        link: BANNER
      },
      // {
      //   parent: "Partner",
      //   link: PARTNER
      // },
      {
        parent: "Feedback",
        link: FEEDBACK
      },
      {
        parent: "Genset Grid",
        link: GENSET_GRID
      },
      {
        parent: "Truck Grid",
        link: TRUCK_GRID
      },
    ]
  },
];