import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Outlet, useNavigate } from "react-router-dom";
import { FormAction, FormProps } from "../../../interfaces/form-action-interfaces";
import { useBrandStore } from "../../brand/states/brand.state";
import { Card, CardHeader, CardBody, CardFooter } from "../../forms/components/card";
import { PageTitle } from "../../forms/components/page-title";
import { Table } from "../../forms/components/table";
import { EngineTypeData } from "../interfaces/engine-type-result.interface";
import { useEngineTypeStore } from "../states/engine-type.state";

export const EngineTypeLayout = () => {
  return (
    <>
      <PageTitle
        title={'Engine Type'}
        subtitle={'This is an example dashboard created using build-in elements and components.'}
      />
      <Outlet/>
    </>
  );
}

export const EngineTypeScreen = () => {

  const { setEngineTypeParams, engineTypeParams, engineTypes, getEngineTypes, engineTypeModel, onDeleteEngineType, setSearchFormSelectData } = useEngineTypeStore();
  const { getBrands, brandsSelectData } = useBrandStore();
  const navigate = useNavigate();

  useEffect(() => {
    getBrands({ page: 0, limit: 1000 });
  }, [])

  useEffect(() => {
    getEngineTypes(engineTypeParams);
  }, [engineTypeParams])

  useEffect(() => {
    if (brandsSelectData.length > 0) {
      setSearchFormSelectData(1, brandsSelectData);
    }
  }, [brandsSelectData]);

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected + 1;
    setEngineTypeParams({ 
      ...engineTypeParams,
      page: selectedPage
    })
  }

  const handleOnEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: EngineTypeData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.UPDATE,
        title: 'EngineType',
        subtitle: 'Form EngineType',
        id: item.id,
      } as FormProps
    });
  }

  const handleOnView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: EngineTypeData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.VIEW,
        title: 'EngineType',
        subtitle: 'Form EngineType',
        id: item.id
      } as FormProps
    });
  }

  return (
    <>
      {/* <Card>
        <CardHeader title={"EngineType Search"} />
        <CardBody>
          <FormsComponent
            forms={searchForms}
            setForms={setSearchForms}
          />
        </CardBody>
        <CardFooter>
          <button 
            type="submit" 
            className="btn btn-success btn-lg"
            onClick={(e) => {
              e.preventDefault();
              onSearch();
            }}>
              Search
          </button>
        </CardFooter>
      </Card> */}
      <Card>
        <CardHeader title={'Engine Type'}>
          <div className="btn-actions-pane-right">
            <button 
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                navigate('form', {
                  state: {
                    action: FormAction.CREATE,
                    title: 'Engine Type',
                    subtitle: 'Form Engine Type'
                  } as FormProps
                });
              }}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </CardHeader>
        <CardBody clean={true}>
          <Table
            data={engineTypes}
            actionButton={(index, item) => {
              let data: EngineTypeData = item as EngineTypeData;
              return (
                <div role={'group'} className="btn-group-sm btn-group btn-group-toggle">
                  {
                    <>
                      <button type="button" className="btn btn-primary" onClick={(e) => handleOnView(e, data)}>View</button>
                      <button type="button" className="btn btn-danger" onClick={e => onDeleteEngineType(data.id, engineTypeParams)}>Delete</button>
                      <button type="button" className="btn btn-warning" onClick={e => handleOnEdit(e, data)}>Edit</button>
                    </>
                  }
                </div>
              );
            }}
          />
        </CardBody>
        <CardFooter>
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={engineTypeModel?.data?.meta?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </CardFooter>
      </Card>
    </>
  );
}