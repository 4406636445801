import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { MeModel, UserData } from '../interfaces/me-model.interface';
import { UserResultInterface } from '../interfaces/user-result.interface';
import { DeleteUser, GetAllUser, GetAllUserParam, ShowUser, SubmitUser, UpdateUser } from '../providers/user.provider';

interface UserState {
  userModel?: UserResultInterface;
  users: UserData[];
  usersSelectData: SelectData[];
  userLoading: boolean;
  onDeleteUser: (id: string, params: GetAllUserParam) => void;
  getUsers: (params: GetAllUserParam) => void;
}

export const useUserStore = create<UserState>((set, get) => ({
  userLoading: false,
  userModel: undefined,
  users: [],
  usersSelectData: [],
  getUsers: async (params: GetAllUserParam) => {
    set({ userLoading: true });
    const response = await GetAllUser(params);
    if (response) {
      if (response.status) {
        set({ userModel: response });
        if (response.data.data.length > 0) {
          let transformUsers: UserData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformUsers.push({
              id: item.id,
              email: item.email,
              fullName: item.fullName,
              phoneNumber: item.phoneNumber,
              roleName: item.role.name,
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.fullName
            });
          }
          set({ users: transformUsers, usersSelectData: transformSelectDatas });
        }
      }
    }
    set({ userLoading: false });
  },
  onDeleteUser: async (id: string, params: GetAllUserParam) => {
    const response = await DeleteUser(id);
    if (response.status) {
      get().getUsers(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface UserFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: MeModel;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useUserFormStore = create<UserFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowUser(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: UserData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowUser(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Avatar',
        placeholder: 'Avatar...',
        type: 'file-upload-v2',
        name: 'avatar',
        value: data?.avatar,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: 'Full Name',
        placeholder: 'Full Name...',
        type: 'text',
        name: 'fullName',
        value: data?.fullName ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Email',
        placeholder: 'Email...',
        type: 'text',
        name: 'email',
        value: data?.email ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Phone Number',
        placeholder: 'Phone Number...',
        type: 'text',
        name: 'phoneNumber',
        value: data?.phoneNumber ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Password',
        placeholder: 'Password...',
        type: 'password',
        name: 'password',
        value: '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Role',
        placeholder: 'Role...',
        type: 'select-with-text',
        name: 'roleId',
        selectData: [],
        value: {
          label: data?.role?.name,
          value: data?.role?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: MeModel = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitUser(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateUser(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
