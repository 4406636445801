import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { BrandData, BrandResultInterface } from "../interfaces/brand-result.interface";
import { BrandSingleResultInterface } from "../interfaces/brand-single-result.interface";

export interface GetAllBrandParam {
  page?: number,
  limit?: number,
}

export const GetAllBrand = async (params: GetAllBrandParam): Promise<BrandResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/brands',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: BrandResultInterface = a.response?.data;
    return response;
  }
}

export const ShowBrand = async (brandId: string): Promise<BrandSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/brands/' + brandId,
    );
    return response.data as BrandSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: BrandSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitBrand = async (form: BrandData): Promise<BrandSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/brands',
      form
    );
    return response.data as BrandSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: BrandSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateBrand = async (id: string, form: BrandData): Promise<BrandSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/brands/' + id,
      form
    );
    return response.data as BrandSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: BrandSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteBrand = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/brands/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}