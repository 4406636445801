import moment from 'moment';
import { FormInput } from '../interfaces/form-action-interfaces';

export const generateFormLogic = (type: FormInput["type"], value: any) => {
  switch (type) {
    case 'select-with-text':
      return value['value'];
    case 'select-with-text-multi':
      let ids = [];
      if (value.length > 0) {
        ids = value.map((v, k) => v.value);
      }
      return ids;
    case 'date':
      return moment(value).format("YYYY-MM-DD");
    case 'datetime':
      return moment(value).format("YYYY-MM-DD H:mm:ss");
    default:
      return value;
  }
}