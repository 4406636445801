import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { GensetGridData, GensetGridResultInterface } from "../interfaces/genset-grid-result.interface";
import { GensetGridSingleResultInterface } from "../interfaces/genset-grid-single-result.interface";

export interface GetAllGensetGridParam {
  page?: number,
  limit?: number,
}

export const GetAllGensetGrid = async (params: GetAllGensetGridParam): Promise<GensetGridResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/genset-grids',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: GensetGridResultInterface = a.response?.data;
    return response;
  }
}

export const ShowGensetGrid = async (gensetGridId: string): Promise<GensetGridSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/genset-grids/' + gensetGridId,
    );
    return response.data as GensetGridSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GensetGridSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitGensetGrid = async (form: GensetGridData): Promise<GensetGridSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/genset-grids',
      form
    );
    return response.data as GensetGridSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GensetGridSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateGensetGrid = async (id: string, form: GensetGridData): Promise<GensetGridSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/genset-grids/' + id,
      form
    );
    return response.data as GensetGridSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GensetGridSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteGensetGrid = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/genset-grids/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}