import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { FeedbackData, FeedbackResultInterface } from '../interfaces/feedback-result.interface';
import { FeedbackSingleResultInterface } from '../interfaces/feedback-single-result.interface';
import { DeleteFeedback, GetAllFeedback, GetAllFeedbackParam, ShowFeedback, SubmitFeedback, UpdateFeedback } from '../providers/feedback.provider';

interface FeedbackState {
  feedbackModel?: FeedbackResultInterface;
  feedbacks: FeedbackData[];
  feedbacksSelectData: SelectData[];
  feedbackLoading: boolean;
  onDeleteFeedback: (id: string, params: GetAllFeedbackParam) => void;
  getFeedbacks: (params: GetAllFeedbackParam) => void;
}

export const useFeedbackStore = create<FeedbackState>((set, get) => ({
  feedbackLoading: false,
  feedbackModel: undefined,
  feedbacks: [],
  feedbacksSelectData: [],
  getFeedbacks: async (params: GetAllFeedbackParam) => {
    set({ feedbackLoading: true });
    const response = await GetAllFeedback(params);
    if (response) {
      if (response.status) {
        set({ feedbackModel: response });
        if (response.data.data.length > 0) {
          let transformFeedbacks: FeedbackData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformFeedbacks.push({
              id: item.id,
              name: item.name,
              email: item.email,
              phoneNumber: item.phoneNumber,
              message: item.message,
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ feedbacks: transformFeedbacks, feedbacksSelectData: transformSelectDatas });
        }
      }
    }
    set({ feedbackLoading: false });
  },
  onDeleteFeedback: async (id: string, params: GetAllFeedbackParam) => {
    const response = await DeleteFeedback(id);
    if (response.status) {
      get().getFeedbacks(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface FeedbackFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: FeedbackSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useFeedbackFormStore = create<FeedbackFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowFeedback(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: FeedbackData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowFeedback(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Email',
        placeholder: 'Email...',
        type: 'text',
        name: 'email',
        value: data?.email ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Phone Number',
        placeholder: 'Phone Number...',
        type: 'text',
        name: 'phoneNumber',
        value: data?.phoneNumber ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Message',
        placeholder: 'Message...',
        type: 'rich-text',
        name: 'message',
        value: data?.message ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: FeedbackSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitFeedback(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateFeedback(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
