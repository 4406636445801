export const ROOT = '/';
export const HOME = '/home';
export const LOGIN = '/login';
export const DASHBOARD = 'dashboard';
export const USER = 'user';
export const ROLE = 'role';
export const BRAND = 'brand';
export const CATEGORY = 'category';
export const SITE = 'site';
export const PRODUCT = 'product';
export const BANNER = 'banner';
export const PARTNER = 'partner';
export const FEEDBACK = 'feedback';
export const ENGINE_TYPE = 'engine-type';
export const GENSET_GRID = 'genset-grid';
export const TRUCK_GRID = 'truck-grid';
export const FORM = 'form';