import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProps } from "../../../interfaces/form-action-interfaces";
import { Card, CardBody, CardFooter, CardHeader } from "../../forms/components/card";
import FormsComponent from "../../forms/components/forms.component";
import { useGensetGridFormStore } from "../states/genset-grid.state";

const GensetGridFormScreen = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { formParam, setFormParam, forms, setForms, generateForms, onSubmitForm, submitDone, reset } = useGensetGridFormStore();

  useEffect(() => {
    if (location) {
      setFormParam(location.state as FormProps);
      generateForms(location.state as FormProps);
    }
  }, [location]);

  useEffect(() => {
    console.log(submitDone);
    if (submitDone) {
      reset();
      navigate(-1)
    }
  }, [submitDone])

  return (
    <form onSubmit={e => {
      e.preventDefault();
      onSubmitForm();
    }}>
      <Card>
        <CardHeader title={`${formParam?.action} ${formParam?.title}`}/>  
        <CardBody>
          <FormsComponent
            forms={forms}
            setForms={setForms}
          />
        </CardBody>
        <CardFooter>
          <button 
            type="submit" 
            className="btn btn-success btn-lg"
            onClick={(e) => {
              e.preventDefault();
              onSubmitForm();
            }}>
              Submit
          </button>
        </CardFooter>
      </Card>  
    </form>
  );
}

export default GensetGridFormScreen;