import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSidebarStore } from '../../../contexts/sidebar/sidebar.state';

export const Sidebar = () => {

  const { sideBarItem } = useSidebarStore();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [childActiveIndex, setChildActiveIndex] = useState<number>(0);

  return (
    <div className="app-sidebar sidebar-shadow">
      {/* <HeaderLogo/>
      <HeaderMobileMenu/>
      <HeaderMenu/> */}
    <div className="scrollbar-sidebar">
        <div className="app-sidebar__inner">
          <ul className="vertical-nav-menu">
          <li className="app-sidebar__heading">Menu</li>
          {
            sideBarItem.map((item, index) => {
              return (
                <li 
                  key={index} 
                  className={index === activeIndex ? 'mm-active' : ''}
                  onClick={() => setActiveIndex(index)}>
                  <a 
                    href={`#${item.parent}`}
                    aria-expanded={index === activeIndex ? true : false}>
                    <i className="metismenu-icon pe-7s-rocket"></i>
                    {item.parent}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                  </a>
                  {
                    item.childs.length > 0 
                    ? 
                    <ul className={`mm-collapse ${activeIndex === index ? 'mm-show' : ''}`}>
                      {
                        item.childs.map((child, key) => {
                          return (
                            <li 
                              key={key}
                              onClick={e => {
                                e.preventDefault();
                                setChildActiveIndex(key);
                              }}>
                              <Link 
                                type="a" 
                                to={{
                                  pathname: child.link,
                                }}
                                state={child.state}
                                className={activeIndex === index ? childActiveIndex === key ? 'mm-active' : '' : ''}
                                >
                                <i className="metismenu-icon"></i>
                                {child.parent}
                              </Link>
                            </li>
                          );
                        })
                      }
                    </ul>
                    : <></>
                  }
                </li>
              );
            })
          }
          </ul>
        </div>
      </div>
    </div>
  );
}