import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { BannerData, BannerResultInterface } from '../interfaces/banner-result.interface';
import { BannerSingleResultInterface } from '../interfaces/banner-single-result.interface';
import { DeleteBanner, GetAllBanner, GetAllBannerParam, ShowBanner, SubmitBanner, UpdateBanner } from '../providers/banner.provider';

interface BannerState {
  bannerModel?: BannerResultInterface;
  banners: BannerData[];
  bannersSelectData: SelectData[];
  bannerLoading: boolean;
  onDeleteBanner: (id: string, params: GetAllBannerParam) => void;
  getBanners: (params: GetAllBannerParam) => void;
}

export const useBannerStore = create<BannerState>((set, get) => ({
  bannerLoading: false,
  bannerModel: undefined,
  banners: [],
  bannersSelectData: [],
  getBanners: async (params: GetAllBannerParam) => {
    set({ bannerLoading: true });
    const response = await GetAllBanner(params);
    if (response) {
      if (response.status) {
        set({ bannerModel: response });
        if (response.data.data.length > 0) {
          let transformBanners: BannerData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformBanners.push({
              id: item.id,
              title: item.title,
              media: item.media
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.title
            });
          }
          set({ banners: transformBanners, bannersSelectData: transformSelectDatas });
        }
      }
    }
    set({ bannerLoading: false });
  },
  onDeleteBanner: async (id: string, params: GetAllBannerParam) => {
    const response = await DeleteBanner(id);
    if (response.status) {
      get().getBanners(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface BannerFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: BannerSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useBannerFormStore = create<BannerFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowBanner(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: BannerData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowBanner(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Image',
        placeholder: 'Image...',
        type: 'file-upload-v2',
        name: 'media',
        value: data?.media,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: 'Title',
        placeholder: 'Title...',
        type: 'text',
        name: 'title',
        value: data?.title ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Description',
        placeholder: 'Description...',
        type: 'text-area',
        name: 'description',
        value: data?.description ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Link',
        placeholder: 'Link...',
        type: 'text',
        name: 'link',
        value: data?.link ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      }
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: BannerSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitBanner(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateBanner(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
