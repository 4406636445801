import Axios, { AxiosInstance } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { AuthState } from '../modules/auth/states/auth.state';

export const Api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const ApiAuthorized = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  }
})

export const AxiosInterceptorSetup = (navigate: NavigateFunction, useAuthStore: AuthState) => {
  // authStore
  const { logout } = useAuthStore;

  // interceptor for request
  ApiAuthorized.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const token = localStorage.getItem("token");

    // add token to header
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
  });

  // interceptor for response
  ApiAuthorized.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log(error.response.status)
    if (error.request.status === 401) {
      localStorage.clear();
      logout();
      navigate('/login');
    }
    return Promise.reject(error)
  });
}

export const ApiAuthorizedUploadFile = () => {
  let token = localStorage.getItem('token');
  return Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`
    },
  });
}

export const ApiUploadFile = (): AxiosInstance => {
  let apiKey = process.env?.REACT_APP_STORAGE_KEY;
  return Axios.create({
    baseURL: process.env.REACT_APP_STORAGE_URL!,
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
      'storage-api-key': apiKey ?? '',
    }
  });
}