import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { TruckGridData, TruckGridResultInterface } from '../interfaces/truck-grid-result.interface';
import { TruckGridSingleResultInterface } from '../interfaces/truck-grid-single-result.interface';
import { DeleteTruckGrid, GetAllTruckGrid, GetAllTruckGridParam, ShowTruckGrid, SubmitTruckGrid, UpdateTruckGrid } from '../providers/truck-grid.provider';

interface TruckGridState {
  truckGridModel?: TruckGridResultInterface;
  truckGrids: TruckGridData[];
  truckGridsSelectData: SelectData[];
  truckGridLoading: boolean;
  onDeleteTruckGrid: (id: string, params: GetAllTruckGridParam) => void;
  getTruckGrids: (params: GetAllTruckGridParam) => void;
}

export const useTruckGridStore = create<TruckGridState>((set, get) => ({
  truckGridLoading: false,
  truckGridModel: undefined,
  truckGrids: [],
  truckGridsSelectData: [],
  getTruckGrids: async (params: GetAllTruckGridParam) => {
    set({ truckGridLoading: true });
    const response = await GetAllTruckGrid(params);
    if (response) {
      if (response.status) {
        set({ truckGridModel: response });
        if (response.data.data.length > 0) {
          let transformTruckGrids: TruckGridData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformTruckGrids.push({
              id: item.id,
              name: item.name,
              media: item.media,
              sort: item.sort
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ truckGrids: transformTruckGrids, truckGridsSelectData: transformSelectDatas });
        }
      }
    }
    set({ truckGridLoading: false });
  },
  onDeleteTruckGrid: async (id: string, params: GetAllTruckGridParam) => {
    const response = await DeleteTruckGrid(id);
    if (response.status) {
      get().getTruckGrids(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface TruckGridFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: TruckGridSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useTruckGridFormStore = create<TruckGridFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowTruckGrid(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: TruckGridData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowTruckGrid(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Media',
        placeholder: 'Media...',
        type: 'file-upload-v2',
        name: 'media',
        value: data?.media ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Sort',
        placeholder: 'Sort...',
        type: 'text',
        name: 'sort',
        value: data?.sort ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: TruckGridSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitTruckGrid(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateTruckGrid(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
