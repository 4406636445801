import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { EngineTypeData, EngineTypeResultInterface } from '../interfaces/engine-type-result.interface';
import { EngineTypeSingleResultInterface } from '../interfaces/engine-type-single-result.interface';
import { DeleteEngineType, GetAllEngineType, GetAllEngineTypeParam, ShowEngineType, SubmitEngineType, UpdateEngineType } from '../providers/engine-type.provider';

interface EngineTypeState {
  engineTypeModel?: EngineTypeResultInterface;
  engineTypes: EngineTypeData[];
  engineTypesSelectData: SelectData[];
  engineTypeLoading: boolean;
  onDeleteEngineType: (id: string, params: GetAllEngineTypeParam) => void;
  getEngineTypes: (params: GetAllEngineTypeParam) => void;
  searchForms: FormInput[];
  engineTypeParams: GetAllEngineTypeParam;
  setEngineTypeParams: (data: GetAllEngineTypeParam) => void;
  setSearchForms: (index: number, value: any) => void;
  setSearchFormSelectData: (index: number, selectData: SelectData[]) => void;
}

export const useEngineTypeStore = create<EngineTypeState>((set, get) => ({
  engineTypeLoading: false,
  engineTypeModel: undefined,
  engineTypes: [],
  engineTypesSelectData: [],
  setEngineTypeParams: (data: GetAllEngineTypeParam) => {
    set({ engineTypeParams: data });
  },
  engineTypeParams: {
    page: 0,
    limit: 10
  },
  searchForms: [
    {
      title: 'EngineType Name',
      placeholder: 'EngineType Name...',
      type: 'text',
      name: 'name',
      value: '',
      disabled: false,
    },
    {
      title: 'Brand',
      placeholder: 'Brand...',
      type: 'select-with-text',
      name: 'brandId',
      selectData: [],
      value: {
        label: 'Choose brand',
        value: '',
      },
      disabled: false
    },
  ],
  setSearchForms: (index: number, value: any) => {
    const forms = [...get().searchForms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ searchForms: forms });
  },
  setSearchFormSelectData: (index: number, selectData: SelectData[]) => {
    const newSelectData: SelectData[] = [
      {
        label: 'Choose brand...',
        value: ''
      }
    ];
    newSelectData.push(...selectData);
    const forms = [...get().searchForms];
    forms[index] = {
      ...forms[index],
      selectData: newSelectData,
    }
    set({ searchForms: forms });
  },
  getEngineTypes: async (params: GetAllEngineTypeParam) => {
    set({ engineTypeLoading: true });
    const response = await GetAllEngineType(params);
    if (response) {
      if (response.status) {
        set({ engineTypeModel: response });
        if (response.data.data.length > 0) {
          let transformEngineTypes: EngineTypeData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformEngineTypes.push({
              id: item.id,
              name: item.name,
              brandName: item.brand.name
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ engineTypes: transformEngineTypes, engineTypesSelectData: transformSelectDatas });
        } else {
          set({ engineTypes: [], engineTypesSelectData: [] });
        }
      } else {
        set({ engineTypes: [], engineTypesSelectData: [] });
      }
    } else {
      set({ engineTypes: [], engineTypesSelectData: [] });
    }
    set({ engineTypeLoading: false });
  },
  onDeleteEngineType: async (id: string, params: GetAllEngineTypeParam) => {
    const response = await DeleteEngineType(id);
    if (response.status) {
      get().getEngineTypes(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  },
}));

interface EngineTypeFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: EngineTypeSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useEngineTypeFormStore = create<EngineTypeFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowEngineType(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: EngineTypeData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowEngineType(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Description',
        placeholder: 'Description...',
        type: 'rich-text',
        name: 'description',
        value: data?.description ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Brand',
        placeholder: 'Brand...',
        type: 'select-with-text',
        name: 'brandId',
        selectData: [],
        value: {
          label: data?.brand?.name,
          value: data?.brand?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: EngineTypeSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitEngineType(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateEngineType(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
