import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { SiteData, SiteResultInterface } from '../interfaces/site-result.interface';
import { SiteSingleResultInterface } from '../interfaces/site-single-result.interface';
import { DeleteSite, GetAllSite, GetAllSiteParam, ShowSite, SubmitSite, UpdateSite } from '../providers/site.provider';

interface SiteState {
  siteModel?: SiteResultInterface;
  sites: SiteData[];
  sitesSelectData: SelectData[];
  siteLoading: boolean;
  onDeleteSite: (id: string, params: GetAllSiteParam) => void;
  getSites: (params: GetAllSiteParam) => void;
}

export const useSiteStore = create<SiteState>((set, get) => ({
  siteLoading: false,
  siteModel: undefined,
  sites: [],
  sitesSelectData: [],
  getSites: async (params: GetAllSiteParam) => {
    set({ siteLoading: true });
    const response = await GetAllSite(params);
    if (response) {
      if (response.status) {
        set({ siteModel: response });
        if (response.data.data.length > 0) {
          let transformSites: SiteData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformSites.push({
              id: item.id,
              siteName: item.content?.siteName
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.content?.siteName
            });
          }
          set({ sites: transformSites, sitesSelectData: transformSelectDatas });
        }
      }
    }
    set({ siteLoading: false });
  },
  onDeleteSite: async (id: string, params: GetAllSiteParam) => {
    const response = await DeleteSite(id);
    if (response.status) {
      get().getSites(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface SiteFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: SiteSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useSiteFormStore = create<SiteFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowSite(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: SiteData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowSite(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Brand Logo',
        placeholder: 'Brand Logo...',
        type: 'file-upload-v2',
        name: 'brandLogo',
        value: data?.content?.brandLogo,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: 'Site Name',
        placeholder: 'Site Name...',
        type: 'text',
        name: 'siteName',
        value: data?.content?.siteName ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Address',
        placeholder: 'Address...',
        type: 'text-area',
        name: 'address',
        value: data?.content?.address ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Phones',
        placeholder: 'Phones...',
        type: 'tags',
        name: 'phones',
        value: data?.content?.phones ?? [],
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Whatsapp',
        placeholder: 'Whatsapp...',
        type: 'text',
        name: 'whatsapp',
        value: data?.content?.whatsapp ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Email',
        placeholder: 'Email...',
        type: 'text',
        name: 'email',
        value: data?.content?.email ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'WhatsApp Permalink',
        placeholder: 'WhatsApp Permalink...',
        type: 'text',
        name: 'whatsappLink',
        value: data?.content?.whatsappLink ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Google Maps',
        placeholder: 'Google Maps...',
        type: 'text',
        name: 'googleMaps',
        value: data?.content?.googleMaps ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Tokopedia',
        placeholder: 'Tokopedia...',
        type: 'text',
        name: 'tokopedia',
        value: data?.content?.tokopedia ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Shopee',
        placeholder: 'Shopee...',
        type: 'text',
        name: 'shopee',
        value: data?.content?.shopee ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Twitter',
        placeholder: 'Twitter...',
        type: 'text',
        name: 'twitter',
        value: data?.content?.twitter ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Facebook',
        placeholder: 'Facebook...',
        type: 'text',
        name: 'facebook',
        value: data?.content?.facebook ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Youtube',
        placeholder: 'Youtube...',
        type: 'text',
        name: 'youtube',
        value: data?.content?.youtube ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let theFormData = {
        content: formData,
      }
      let res: SiteSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitSite(theFormData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateSite(get().formParam.id, theFormData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
