import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import LoginScreen from './modules/auth/screens/login.screen';
import ProtectedRoute from './router/PrivateRoute';
import { useEffect } from 'react';
import { useAuthStore } from './modules/auth/states/auth.state';
import AxiosInterceptorNavigate from './modules/auth/screens/axios-interceptor.screen';
import HomeLayout from './modules/home/screens/home.screen';

function App() {

  const { checkAuth } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, []);
  
  return (
    <>
      <BrowserRouter>
        {<AxiosInterceptorNavigate/>}
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/*" element={<HomeLayout />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
