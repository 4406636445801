import { FormInput } from "../../../interfaces/form-action-interfaces";
import { RichText } from "./ckeditor/rich-text";
import { FileUploadMulti } from "./file-upload-multi";
import { FileUploadV2 } from "./file-upload-v2";
import { Input, SelectWithText } from "./input";

interface FormsComponentProps {
  forms: FormInput[];
  setForms: (index: number, value: any) => void;
}

const FormsComponent: React.FC<FormsComponentProps> = ({
  forms, setForms
}) => {
  return (
    <>
      {
        forms?.map((v, k) => {
          switch (v?.type) {
            case 'file-upload-v2':
              return <FileUploadV2
                key={k}
                title={v.title}
                multiple={false}
                description={'Upload File'}
                name={v.name}
                onUploadDone={(e) => {
                  setForms(k, e.data?.id);
                }}
                value={v.value}
                disabled={v.disabled}
                />;  
            case 'rich-text':
              return <RichText
                key={k}
                title={v.title}
                value={v.value}
                name={v.name}
                disabled={v.disabled}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setForms(k, data);
                  console.log( { event, editor, data } );
                }}
                onBlur={( event, editor) => {
                  console.log( 'Blur.', editor );
                }}
                onFocus={( event, editor) => {
                  console.log( 'Focus.', editor );
                }}
              />;
            case 'file-upload-multi':
              return <FileUploadMulti
                key={k}
                title={v.title}
                multiple={true}
                description={'Upload File'}
                name={v.name}
                onUploadDone={(e) => {
                  const ids = e.data.map((v, k) => v.id);
                  setForms(k, ids);
                }}
                value={v.value}
                disabled={v.disabled}
                />;  
            case 'number':
              return <Input
                key={k}
                title={v.title}
                type={v.type}
                placeholder={v.placeholder}
                name={v.name}
                value={v.value}
                disabled={v.disabled}
                onChange={(e) => {
                  setForms(k, e.target.value);
                }}
              />
            case 'email':
              return <Input
                key={k}
                title={v.title}
                type={v.type}
                placeholder={v.placeholder}
                name={v.name}
                value={v.value}
                disabled={v.disabled}
                onChange={(e) => {
                  setForms(k, e.target.value);
                }}
              />
            case 'password':
              return <Input
                key={k}
                title={v.title}
                type={v.type}
                placeholder={v.placeholder}
                name={v.name}
                value={v.value}
                disabled={v.disabled}
                onChange={(e) => {
                  setForms(k, e.target.value);
                }}
              />
            case 'text':
              return <Input
                key={k}
                title={v.title}
                type={v.type}
                placeholder={v.placeholder}
                name={v.name}
                value={v.value}
                disabled={v.disabled}
                onChange={(e) => {
                  setForms(k, e.target.value);
                }}
              />
            case 'select-with-text':
              return <SelectWithText 
                key={k}
                title={v.title}
                name={v.name}
                selectData={v.selectData}
                type={v.type}
                value={v.value}
                disabled={v.disabled}
                onChangeSelectWithText={e => {
                  console.log(e)
                  setForms(k, e);
                }}
                onInputChange={v.onInputChange}
              />
            case 'select-with-text-multi':
              return <SelectWithText 
                key={k}
                title={v.title}
                name={v.name}
                selectData={v.selectData}
                type={v.type}
                value={v.value}
                disabled={v.disabled}
                isMulti={v.isMulti}
                onChangeSelectWithText={e => {
                  console.log(e)
                  setForms(k, e);
                }}
                onInputChange={v.onInputChange}
              />
            default:
              return <div key={k}></div>
          }
        })
      }
    </>
  );
}

export default FormsComponent;