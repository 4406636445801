import { WithContext as ReactTags } from 'react-tag-input';
import Select from 'react-select';
import './styles/react-tags.scss';
import DatePicker from "react-datepicker";
import { FormInput } from '../../../interfaces/form-action-interfaces';

const Input: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  hasInputGroup,
  inputGroupAction,
  inputGroupTitle,
  hidden = false,
}) => {
  return (
    <div className="position-relative form-group">
      {
        !hidden && <label className="">{title}</label>
      }
      <div className="input-group">
        <input 
          onChange={onChange} 
          placeholder={placeholder} 
          value={value}
          type={type}
          name={name}
          disabled={disabled}
          hidden={hidden}
          className="form-control"/>
          {
            hasInputGroup &&
            <div className="input-group-append">
              <button 
                className="btn btn-secondary" 
                onClick={inputGroupAction}>{inputGroupTitle}</button>
            </div>
          }
        </div>
    </div>  
  );
}

const InputTextArea: React.FC<FormInput> = ({
  title,
  placeholder,
  onChangeTextArea,
  value,
  name,
  disabled = false,
  hidden = false,
}) => {
  return (
    <div className="position-relative form-group">
      {
        !hidden && <label className="">{title}</label>
      }
      <div className="input-group">
        <textarea 
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChangeTextArea}
          className="form-control"
          hidden={hidden}
          disabled={disabled}>
          {value}
        </textarea>
      </div>
    </div>  
  );
}

const SelectInput: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  selectData,
  onChangeSelect,
  disabled = false,
}) => {
  return (
    <div className="position-relative form-group">
      <label className="">{title}</label>
      <select 
        className="mb-2 form-control"
        name={name}
        disabled={disabled}
        onChange={onChangeSelect}
        value={value}>
        {
          selectData?.map((v, k) => (
            <option key={k} value={v.value} selected={value === v.value ? true : false}>{v.label}</option>
          ))
        }
      </select>
    </div>  
  );
}

const TagInput: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  tags,
  suggestions,
  handleAddition,
  handleDelete,
  handleDrag,
}) => {

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };
  const delimiters = [...KeyCodes.enter, KeyCodes.comma];

  return (
    <div className="position-relative form-group">
      <label className="">{title}</label>
      <ReactTags
        name={name}
        inline={true}
        tags={tags}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        readOnly={disabled}
      />
    </div>
  );
}

const SelectWithText: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeSelectWithText,
  selectData,
  onInputChange,
  isMulti = false,
}) => {

  return (
    <div className="position-relative form-group">
      <label className="">{title}</label>
      <Select 
        isMulti={isMulti}
        options={selectData} 
        placeholder={placeholder}
        onChange={onChangeSelectWithText}
        name={name}
        value={value}
        isDisabled={disabled}
        onInputChange={onInputChange}
      />
    </div>  
  )
}

const DateTimePicker: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeDateTime,
  onChangeDate
}) => {
  return (
    <div className="position-relative form-group">
      <label>{title}</label>
      <input type="hidden" value={value} name={name}/>
      <DatePicker
        dateFormat="MM/dd/yyyy, H:mm"
        name={name}
        className={'form-control'}
        selected={value} 
        disabled={disabled}
        timeInputLabel="Time:"
        showTimeInput
        onChange={onChangeDate} />
    </div>
  );
}

const FormDatePicker: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeDate
}) => {
  return (
    <div className="position-relative form-group">
      <label>{title}</label>
      {/* <input type="hidden" value={value} name={name}/> */}
      <DatePicker 
        dateFormat="yyyy-MM-dd"
        name={name}
        className={'form-control'}
        selected={value} 
        disabled={disabled}
        onChange={onChangeDate} />
    </div>
  );
}

export {
  Input,
  SelectInput,
  TagInput,
  SelectWithText,
  DateTimePicker,
  FormDatePicker,
  InputTextArea
}