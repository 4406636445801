import { useContext, useEffect, useState } from "react";
import { generateImageUrl } from "../../../helpers/generate-image-url";
import { MultipleFileUploadResult } from "../../upload/interface/multiple-file-upload-result";
import { UploadContextProvider, UploadContext, UploadContextType } from "../../upload/states/upload-context";

interface FileUploadMultiProps {
  title: string;
  name?: string;
  description?: string;
  multiple: boolean;
  value?: string[];
  disabled: boolean;
  onUploadDone?: (file: MultipleFileUploadResult) => void;
}

export const FileUploadMulti: React.FC<FileUploadMultiProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled,
  onUploadDone
}) => {
  return (
    <UploadContextProvider>
      <FileUploadMultiState
        title={title}
        name={name}
        description={description}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onUploadDone={onUploadDone}
      />
    </UploadContextProvider>
  );
}

const FileUploadMultiState: React.FC<FileUploadMultiProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone
}) => {

  const { setMultipleFile, resultMultipleFile, total, loaded } = useContext(UploadContext) as UploadContextType;
  const [width, setWidth] = useState<string>('0%');

  useEffect(() => {
    console.log(value);
  }, [value]);

  useEffect(() => {
    let t = Math.floor((loaded / total) * 100)
    let s = t.toString() + '%';
    setWidth(s);
  }, [total, loaded]);

  useEffect(() => {
    if (resultMultipleFile !== undefined) {
      onUploadDone(resultMultipleFile);
    }
  }, [resultMultipleFile])
  
  return (
    <div className="position-relative form-group">
      <label>{title}</label>

      {
        value !== undefined && value.length > 0 &&
        value.map((item, index) => (
          <img
            key={index}
            src={generateImageUrl(item)}
            alt={'my_avatar'}
            style={{
              width: '100%',
              height: 250,
              borderRadius: 10,
              marginBottom: 10,
              objectFit: 'contain',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
            }}
          />
        ))
      }

      <input
        name={name}
        type="file"
        className="form-control-file"
        disabled={disabled}
        multiple={multiple}
        onChange={e => {
          let files: File[] = [];
          if (e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
              files.push(e.target.files[i]);
            }
          }
          setMultipleFile(files);
        }}
      />
      <small className="form-text text-muted">
        {description}
      </small>
      <div className="mb-3 progress">
        <div 
          className="progress-bar progress-bar-animated bg-success progress-bar-striped" 
          role="progressbar" 
          aria-valuenow={loaded}
          aria-valuemin={0}
          aria-valuemax={total}
          style={{
            width: width
          }}>
            {
              loaded === 0 && total === 0 
              ? ''
              : loaded === total ? 'Upload done' : `${width} Uploading...`
            }
        </div>
      </div>
    </div>
  );
}