import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Outlet, useNavigate } from "react-router-dom";
import { FormAction, FormProps } from "../../../interfaces/form-action-interfaces";
import { Card, CardHeader, CardBody, CardFooter } from "../../forms/components/card";
import { PageTitle } from "../../forms/components/page-title";
import { Table } from "../../forms/components/table";
import { BannerData } from "../interfaces/banner-result.interface";
import { GetAllBannerParam } from "../providers/banner.provider";
import { useBannerStore } from "../states/banner.state";

export const BannerLayout = () => {
  return (
    <>
      <PageTitle
        title={'Banner'}
        subtitle={'This is an example dashboard created using build-in elements and components.'}
      />
      <Outlet/>
    </>
  );
}

export const BannerScreen = () => {
  const { banners, getBanners, bannerModel, onDeleteBanner } = useBannerStore();
  const [bannerParams, setBannerParams] = useState<GetAllBannerParam>({ page: 0, limit: 10 });
  const navigate = useNavigate();

  useEffect(() => {
    getBanners(bannerParams);
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected + 1;
    setBannerParams({ 
      ...bannerParams,
      page: selectedPage
    })
  }

  const handleOnEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: BannerData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.UPDATE,
        title: 'Banner',
        subtitle: 'Form Banner',
        id: item.id,
      } as FormProps
    });
  }

  const handleOnView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: BannerData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.VIEW,
        title: 'Banner',
        subtitle: 'Form Banner',
        id: item.id
      } as FormProps
    });
  }

  return (
    <>
      <Card>
        <CardHeader title={'Banner'}>
          <div className="btn-actions-pane-right">
            <button 
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                navigate('form', {
                  state: {
                    action: FormAction.CREATE,
                    title: 'Banner',
                    subtitle: 'Form Banner'
                  } as FormProps
                });
              }}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </CardHeader>
        <CardBody clean={true}>
          <Table
            data={banners}
            actionButton={(index, item) => {
              let data: BannerData = item as BannerData;
              return (
                <div role={'group'} className="btn-group-sm btn-group btn-group-toggle">
                  {
                    <>
                      <button type="button" className="btn btn-primary" onClick={(e) => handleOnView(e, data)}>View</button>
                      <button type="button" className="btn btn-danger" onClick={e => onDeleteBanner(data.id, bannerParams)}>Delete</button>
                      <button type="button" className="btn btn-warning" onClick={e => handleOnEdit(e, data)}>Edit</button>
                    </>
                  }
                </div>
              );
            }}
          />
        </CardBody>
        <CardFooter>
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={bannerModel?.data?.meta?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </CardFooter>
      </Card>
    </>
  );
}