import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProps } from "../../../interfaces/form-action-interfaces";
import { Card, CardBody, CardFooter, CardHeader } from "../../forms/components/card";
import { Input } from "../../forms/components/input";
import { useCategoryFormStore } from "../states/category.state";

const CategoryFormScreen = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { formParam, setFormParam, forms, setForms, generateForms, onSubmitForm, submitDone, reset } = useCategoryFormStore();

  useEffect(() => {
    if (location) {
      setFormParam(location.state as FormProps);
      generateForms(location.state as FormProps);
    }
  }, [location]);

  useEffect(() => {
    console.log(submitDone);
    if (submitDone) {
      reset();
      navigate(-1)
    }
  }, [submitDone])

  return (
    <form onSubmit={e => {
      e.preventDefault();
      onSubmitForm();
    }}>
      <Card>
        <CardHeader title={`${formParam?.action} ${formParam?.title}`}/>  
        <CardBody>
          {
            forms?.map((v, k) => {
              switch (v?.type) {
                case 'email':
                  return <Input
                    key={k}
                    title={v.title}
                    type={v.type}
                    placeholder={v.placeholder}
                    name={v.name}
                    value={v.value}
                    disabled={v.disabled}
                    onChange={(e) => {
                      setForms(k, e.target.value);
                    }}
                  />
                case 'password':
                  return <Input
                    key={k}
                    title={v.title}
                    type={v.type}
                    placeholder={v.placeholder}
                    name={v.name}
                    value={v.value}
                    disabled={v.disabled}
                    onChange={(e) => {
                      setForms(k, e.target.value);
                    }}
                  />
                case 'text':
                  return <Input
                    key={k}
                    title={v.title}
                    type={v.type}
                    placeholder={v.placeholder}
                    name={v.name}
                    value={v.value}
                    disabled={v.disabled}
                    onChange={(e) => {
                      setForms(k, e.target.value);
                    }}
                  />
                default:
                  return <div key={k}></div>
              }
            })
          }
        </CardBody>
        <CardFooter>
          <button 
            type="submit" 
            className="btn btn-success btn-lg"
            onClick={(e) => {
              e.preventDefault();
              onSubmitForm();
            }}>
              Submit
          </button>
        </CardFooter>
      </Card>  
    </form>
  );
}

export default CategoryFormScreen;