import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { TruckGridData, TruckGridResultInterface } from "../interfaces/truck-grid-result.interface";
import { TruckGridSingleResultInterface } from "../interfaces/truck-grid-single-result.interface";

export interface GetAllTruckGridParam {
  page?: number,
  limit?: number,
}

export const GetAllTruckGrid = async (params: GetAllTruckGridParam): Promise<TruckGridResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/truck-grids',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: TruckGridResultInterface = a.response?.data;
    return response;
  }
}

export const ShowTruckGrid = async (truckGridId: string): Promise<TruckGridSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/truck-grids/' + truckGridId,
    );
    return response.data as TruckGridSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: TruckGridSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitTruckGrid = async (form: TruckGridData): Promise<TruckGridSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/truck-grids',
      form
    );
    return response.data as TruckGridSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: TruckGridSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateTruckGrid = async (id: string, form: TruckGridData): Promise<TruckGridSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/truck-grids/' + id,
      form
    );
    return response.data as TruckGridSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: TruckGridSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteTruckGrid = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/truck-grids/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}