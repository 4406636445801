import { AxiosError } from "axios";
import { Api, ApiAuthorized } from "../../../api/api";
import { MeModel } from "../../user/interfaces/me-model.interface";
import { AuthModel } from "../interfaces/auth-model";

export const DoLogin = async (username: string, password: string): Promise<AuthModel> => {
  try {
    let data = {
      username: username,
      password: password
    };

    let response = await Api.post(
      '/oauth/access_token/login',
      data
    );
    return response.data as AuthModel;
  } catch (e: any) {
    let a = e as AxiosError;
    let response: AuthModel = a.response?.data
    return response;
  }
}

export const DoLogout = (): boolean => {
  try {
    // remove token from localstorage
    localStorage.removeItem('token');
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const GetMe = async (): Promise<MeModel> => {
  try {

    let response = await ApiAuthorized.get(
      '/oauth/me'
    );
    return response.data as MeModel;
  } catch (e: any) {
    let a = e as AxiosError;
    let response: MeModel = a.response?.data
    return response;
  }
}