import { camelCaseToSpaces } from "../../../helpers/camel-case-to-spaces";

interface TableProps {
  data?: any[];
  actionButton?: (index: number, item: any) => React.ReactNode;
}

const Table: React.FC<TableProps> = ({
  data = [],
  actionButton
}) => {
  return (
    <div className="table-responsive">
      {
        data.length > 0
        ? 
        <table className="mb-0 table table-striped">
          <thead>
            <tr>
              <th>#</th>
              {
                Object.keys(data[0]).map((v, k) => {
                  return (
                    <th key={k}>
                      {camelCaseToSpaces(v)}
                    </th>
                  );
                })
              }
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((v, k) => (
                <tr key={k}>
                  <td>{k+1}</td>
                  {
                    Object.keys(v).map((vv, kk) => {
                      console.log(v, vv)
                      if (vv === 'media') {
                        return <td key={kk}>
                          <img
                            src={v.hasOwnProperty(vv) ? `${process.env.REACT_APP_ASSETS_URL}/${v[vv]}` : 'false'}
                            alt={v[vv]}
                            style={{
                              width: 100
                            }}
                          />
                        </td>
                      }
                      return <td key={kk}>{v.hasOwnProperty(vv) ? v[vv] : 'false'}</td>
                    })
                  }
                  <td>{actionButton(k, v)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        : <p>Tidak ada data</p>
      }
    </div>
  );
}

export {
  Table
}