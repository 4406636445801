import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { MeModel, UserData } from "../interfaces/me-model.interface";
import { UserResultInterface } from "../interfaces/user-result.interface";

export interface GetAllUserParam {
  page?: number,
  limit?: number,
}

export const GetAllUser = async (params: GetAllUserParam): Promise<UserResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/users',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserResultInterface = a.response?.data;
    return response;
  }
}

export const ShowUser = async (roleId: string): Promise<MeModel> => {
  try {
    let response = await ApiAuthorized.get(
      '/users/' + roleId,
    );
    return response.data as MeModel;
  } catch (e) {
    let a = e as AxiosError;
    let response: MeModel = a.response?.data;
    return response;
  }
}

export const SubmitUser = async (form: UserData): Promise<MeModel> => {
  try {
    let response = await ApiAuthorized.post(
      '/users',
      form
    );
    return response.data as MeModel;
  } catch (e) {
    let a = e as AxiosError;
    let response: MeModel = a.response?.data;
    return response;
  }
}

export const UpdateUser = async (id: string, form: UserData): Promise<MeModel> => {
  try {
    let response = await ApiAuthorized.patch(
      '/users/' + id,
      form
    );
    return response.data as MeModel;
  } catch (e) {
    let a = e as AxiosError;
    let response: MeModel = a.response?.data;
    return response;
  }
}

export const DeleteUser = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/users/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}