import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { PartnerData, PartnerResultInterface } from "../interfaces/partner-result.interface";
import { PartnerSingleResultInterface } from "../interfaces/partner-single-result.interface";

export interface GetAllPartnerParam {
  page?: number,
  limit?: number,
}

export const GetAllPartner = async (params: GetAllPartnerParam): Promise<PartnerResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/partners',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: PartnerResultInterface = a.response?.data;
    return response;
  }
}

export const ShowPartner = async (partnerId: string): Promise<PartnerSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/partners/' + partnerId,
    );
    return response.data as PartnerSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PartnerSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitPartner = async (form: PartnerData): Promise<PartnerSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/partners',
      form
    );
    return response.data as PartnerSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PartnerSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdatePartner = async (id: string, form: PartnerData): Promise<PartnerSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/partners/' + id,
      form
    );
    return response.data as PartnerSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PartnerSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeletePartner = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/partners/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}