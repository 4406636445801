import { ISidebarInterface } from "../../interfaces/sidebar-interfaces";
import create from 'zustand';
import { sideBarItem } from "./sidebar-items";

interface SidebarState {
  isSidebarMobileOpen: boolean;
  isSidebarDesktopOpen: boolean;
  setIsSidebarMobileOpen: (value: boolean) => void;
  setIsSidebarDesktopOpen: (value: boolean) => void;
  isHeaderMobileOpen: boolean;
  setIsHeaderMobileOpen: (value: boolean) => void;
  sideBarItem: ISidebarInterface[];
}

export const useSidebarStore = create<SidebarState>((set, get) => ({
  isSidebarDesktopOpen: false,
  isSidebarMobileOpen: false,
  setIsSidebarMobileOpen: (value: boolean) => {
    set({ isSidebarMobileOpen: value })
  },
  setIsSidebarDesktopOpen: (value: boolean) => {
    set({ isSidebarDesktopOpen: value })
  },
  isHeaderMobileOpen: false,
  setIsHeaderMobileOpen: (value: boolean) => {
    set({ isHeaderMobileOpen: value })
  },
  sideBarItem: sideBarItem,
}))