import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { BannerData, BannerResultInterface } from "../interfaces/banner-result.interface";
import { BannerSingleResultInterface } from "../interfaces/banner-single-result.interface";

export interface GetAllBannerParam {
  page?: number,
  limit?: number,
}

export const GetAllBanner = async (params: GetAllBannerParam): Promise<BannerResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/banners',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: BannerResultInterface = a.response?.data;
    return response;
  }
}

export const ShowBanner = async (bannerId: string): Promise<BannerSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/banners/' + bannerId,
    );
    return response.data as BannerSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: BannerSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitBanner = async (form: BannerData): Promise<BannerSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/banners',
      form
    );
    return response.data as BannerSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: BannerSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateBanner = async (id: string, form: BannerData): Promise<BannerSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/banners/' + id,
      form
    );
    return response.data as BannerSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: BannerSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteBanner = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/banners/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}