import { MeModel } from "../../user/interfaces/me-model.interface";
import create from "zustand";
import { DoLogin, GetMe } from "./auth-provider";
import { toast } from "react-toastify";

export interface AuthState {
  message: string;
  loading: boolean;
  isLoggedIn: boolean;
  meModel?: MeModel;
  login: (username: string, password: string) => void;
  checkAuth: () => void;
  logout: () => void;
  getMe: () => void;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  message: "",
  loading: false,
  isLoggedIn: false,
  meModel: undefined,
  checkAuth: () => {
    const token = localStorage.getItem("token");
    if (token) {
      get().getMe();
      set({ isLoggedIn: true });
    } else {
      set({ isLoggedIn: false });
    }
  },
  login: async (username: string, password: string) => {
    set({ loading: true });
    const response = await DoLogin(username, password);

    if (response.status) {
      localStorage.setItem("token", response.data);
      get().getMe();
      toast.success(response.message)
      set({ isLoggedIn: true });
    } else {
      toast.error(response.message)
    }
    
    set({
      message: response.message,
      loading: false,
    });
  },
  logout: () => {
    console.log("test");
    localStorage.removeItem("token");
    set({ isLoggedIn: false });
  },
  getMe: async () => {
    const response = await GetMe();
    set({ meModel: response });
  }
}))