import { Outlet, Route, Routes } from "react-router-dom";
import { BANNER, BRAND, CATEGORY, ENGINE_TYPE, FEEDBACK, FORM, GENSET_GRID, PARTNER, PRODUCT, ROLE, SITE, TRUCK_GRID, USER } from "../../../router/routes";
import BannerFormScreen from "../../banner/screens/banner-form.screen";
import { BannerLayout, BannerScreen } from "../../banner/screens/banner.screen";
import BrandFormScreen from "../../brand/screens/brand-form.screen";
import { BrandLayout, BrandScreen } from "../../brand/screens/brand.screen";
import CategoryFormScreen from "../../category/screens/category-form.screen";
import { CategoryLayout, CategoryScreen } from "../../category/screens/category.screen";
import EngineTypeFormScreen from "../../engine-types/screens/engine-type-form.screen";
import { EngineTypeLayout, EngineTypeScreen } from "../../engine-types/screens/engine-type.screen";
import FeedbackFormScreen from "../../feedback/screens/feedback-form.screen";
import { FeedbackLayout, FeedbackScreen } from "../../feedback/screens/feedback.screen";
import { Container } from "../../forms/components/container";
import GensetGridFormScreen from "../../genset-grid/screens/genset-grid-form.screen";
import { GensetGridLayout, GensetGridScreen } from "../../genset-grid/screens/genset-grid.screen";
import PartnerFormScreen from "../../partner/screens/partner-form.screen";
import { PartnerLayout, PartnerScreen } from "../../partner/screens/partner.screen";
import ProductFormScreen from "../../products/screens/product-form.screen";
import { ProductLayout, ProductScreen } from "../../products/screens/product.screen";
import RoleFormScreen from "../../roles/screens/role-form.screen";
import { RoleLayout, RoleScreen } from "../../roles/screens/role.screen";
import SiteFormScreen from "../../site/screens/site-form.screen";
import { SiteLayout, SiteScreen } from "../../site/screens/site.screen";
import TruckGridFormScreen from "../../truck-grid/screens/truck-grid-form.screen";
import { TruckGridLayout, TruckGridScreen } from "../../truck-grid/screens/truck-grid.screen";
import UserFormScreen from "../../user/screens/user-form.screen";
import { UserLayout, UserScreen } from "../../user/screens/user.screen";
import DashboardScreen from "./dashboard.screen";

const HomeScreen = () => {
  return (<>
    <Container>
      <Outlet/>
    </Container>
  </>);
}

const HomeLayout = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen/>}>
        <Route
          index
          element={<DashboardScreen/>}
        />
        <Route path={ROLE} element={<RoleLayout/>}>
          <Route
            index
            element={<RoleScreen/>}
          />  
          <Route
            path={FORM}
            element={<RoleFormScreen/>}
          />
        </Route>
        <Route path={USER} element={<UserLayout/>}>
          <Route
            index
            element={<UserScreen/>}
          />  
          <Route
            path={FORM}
            element={<UserFormScreen/>}
          />
        </Route>
        <Route path={BRAND} element={<BrandLayout/>}>
          <Route
            index
            element={<BrandScreen/>}
          />  
          <Route
            path={FORM}
            element={<BrandFormScreen/>}
          />
        </Route>
        <Route path={CATEGORY} element={<CategoryLayout/>}>
          <Route
            index
            element={<CategoryScreen/>}
          />  
          <Route
            path={FORM}
            element={<CategoryFormScreen/>}
          />
        </Route>
        <Route path={SITE} element={<SiteLayout/>}>
          <Route
            index
            element={<SiteScreen/>}
          />  
          <Route
            path={FORM}
            element={<SiteFormScreen/>}
          />
        </Route>
        <Route path={PRODUCT} element={<ProductLayout/>}>
          <Route
            index
            element={<ProductScreen/>}
          />  
          <Route
            path={FORM}
            element={<ProductFormScreen/>}
          />
        </Route>
        <Route path={BANNER} element={<BannerLayout/>}>
          <Route
            index
            element={<BannerScreen/>}
          />  
          <Route
            path={FORM}
            element={<BannerFormScreen/>}
          />
        </Route>
        <Route path={PARTNER} element={<PartnerLayout/>}>
          <Route
            index
            element={<PartnerScreen/>}
          />  
          <Route
            path={FORM}
            element={<PartnerFormScreen/>}
          />
        </Route>
        <Route path={FEEDBACK} element={<FeedbackLayout/>}>
          <Route
            index
            element={<FeedbackScreen/>}
          />  
          <Route
            path={FORM}
            element={<FeedbackFormScreen/>}
          />
        </Route>
        <Route path={ENGINE_TYPE} element={<EngineTypeLayout/>}>
          <Route
            index
            element={<EngineTypeScreen/>}
          />  
          <Route
            path={FORM}
            element={<EngineTypeFormScreen/>}
          />
        </Route>
        <Route path={GENSET_GRID} element={<GensetGridLayout/>}>
          <Route
            index
            element={<GensetGridScreen/>}
          />  
          <Route
            path={FORM}
            element={<GensetGridFormScreen/>}
          />
        </Route>
        <Route path={TRUCK_GRID} element={<TruckGridLayout/>}>
          <Route
            index
            element={<TruckGridScreen/>}
          />  
          <Route
            path={FORM}
            element={<TruckGridFormScreen/>}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default HomeLayout;