import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { BrandData, BrandResultInterface } from '../interfaces/brand-result.interface';
import { BrandSingleResultInterface } from '../interfaces/brand-single-result.interface';
import { DeleteBrand, GetAllBrand, GetAllBrandParam, ShowBrand, SubmitBrand, UpdateBrand } from '../providers/brand.provider';

interface BrandState {
  brandModel?: BrandResultInterface;
  brands: BrandData[];
  brandsSelectData: SelectData[];
  brandLoading: boolean;
  onDeleteBrand: (id: string, params: GetAllBrandParam) => void;
  getBrands: (params: GetAllBrandParam) => void;
}

export const useBrandStore = create<BrandState>((set, get) => ({
  brandLoading: false,
  brandModel: undefined,
  brands: [],
  brandsSelectData: [],
  getBrands: async (params: GetAllBrandParam) => {
    set({ brandLoading: true });
    const response = await GetAllBrand(params);
    if (response) {
      if (response.status) {
        set({ brandModel: response });
        if (response.data.data.length > 0) {
          let transformBrands: BrandData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformBrands.push({
              id: item.id,
              name: item.name,
              type: item.type.toLocaleUpperCase()
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ brands: transformBrands, brandsSelectData: transformSelectDatas });
        }
      }
    }
    set({ brandLoading: false });
  },
  onDeleteBrand: async (id: string, params: GetAllBrandParam) => {
    const response = await DeleteBrand(id);
    if (response.status) {
      get().getBrands(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface BrandFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: BrandSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useBrandFormStore = create<BrandFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowBrand(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: BrandData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowBrand(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Image',
        placeholder: 'Image...',
        type: 'file-upload-v2',
        name: 'media',
        value: data?.media,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Type',
        placeholder: 'Type...',
        type: 'select-with-text',
        name: 'type',
        value: {
          label: data?.type,
          value: data?.type
        },
        selectData: [
          {
            label: 'genset',
            value: 'genset',
          },
          {
            label: 'truck',
            value: 'truck',
          },
          {
            label: 'undefined',
            value: 'undefined',
          },
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Is Top Brand?',
        placeholder: 'Is Top Brand?...',
        type: 'toggle',
        name: 'isTopBrand',
        value: data?.isTopBrand ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Is Manufacturer?',
        placeholder: 'Is Manufacturer?...',
        type: 'toggle',
        name: 'isManufacturer',
        value: data?.isManufacturer ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Is Category?',
        placeholder: 'Is Category?...',
        type: 'toggle',
        name: 'isCategory',
        value: data?.isCategory ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: BrandSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitBrand(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateBrand(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
