import { Moment } from "moment";
import React from "react";
import { Tag } from "react-tag-input";

export interface FormProps {
  action: FormAction;
  id?: string;
  title?: string;
  subtitle?: string;
  data?: any;
  leagueId?: string;
  eventId?: string;
  programId?: string;
}

export interface SelectData {
  label?: string;
  value?: string;
}

export interface FormInput {
  title: string;
  name: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeSelectWithText?: (e: SelectData) => void;
  onInputChange?: (e: string) => void;
  type: 'text' 
    | 'select' 
    | 'radio' 
    | 'checkbox' 
    | 'password' 
    | 'email' 
    | 'toggle' 
    | 'file-upload' 
    | 'rich-text' 
    | 'tags' 
    | 'select-with-text' 
    | 'select-with-text-multi' 
    | 'datetime' 
    | 'number' 
    | 'file-upload-v2' 
    | 'file-upload-multi' 
    | 'text-area'
    | 'date';
  value?: any;
  selectData?: SelectData[];
  disabled?: boolean;
  hasInputGroup?: boolean;
  inputGroupAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  inputGroupTitle?: string;
  hidden?: boolean;
  isMulti?: boolean;

  // text-area
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

  // for tags only
  tags?: Tag[],
  suggestions?: Tag[],
  handleDelete?: (i: number) => void;
  handleAddition?: (tag: Tag) => void;
  handleDrag?: (tag: Tag, currPos: number, newPos: number) => void;
  
  // datetime
  onChangeDateTime?: (e: string | Moment) => void;
  onChangeDate?: (date: Date, event: React.SyntheticEvent<any, Event>) => void;
}

export enum FormAction {
  CREATE = 'Create',
  UPDATE = 'Update',
  VIEW = 'View'
}