import { AxiosError } from "axios";
import { ApiUploadFile } from "../../../api/api"
import { MultipleFileUploadResult } from "../interface/multiple-file-upload-result";
import { SingleFileUploadResult } from "../interface/single-file-upload-result";

export const UploadSingle = async (file: File, progress: (e: ProgressEvent) => void): Promise<SingleFileUploadResult> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    
    let res = await ApiUploadFile().post('/storage/single', formData, {
      onUploadProgress: (pg: ProgressEvent) => {
        progress(pg);
      }
    });

    return res.data as SingleFileUploadResult;
  } catch (e) {
    let a = e as AxiosError;
    let response: SingleFileUploadResult = a.response?.data;
    return response;
  }
}

export const UploadMultiple = async (file: File[], progress: (e: ProgressEvent) => void): Promise<MultipleFileUploadResult> => {
  try {
    const formData = new FormData();
    if (file.length > 0) {
      file.forEach((item, index) => {
        formData.append('files', item);
      });
    }

    const res = await ApiUploadFile().post('/storage/multiple', formData, {
      onUploadProgress: (pg: ProgressEvent) => {
        progress(pg);
      }
    });

    return res.data as MultipleFileUploadResult;
  } catch (e) {
    let a = e as AxiosError;
    let response: MultipleFileUploadResult = a.response?.data;
    return response;
  }
}