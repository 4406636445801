import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { PartnerData, PartnerResultInterface } from '../interfaces/partner-result.interface';
import { PartnerSingleResultInterface } from '../interfaces/partner-single-result.interface';
import { DeletePartner, GetAllPartner, GetAllPartnerParam, ShowPartner, SubmitPartner, UpdatePartner } from '../providers/partner.provider';

interface PartnerState {
  partnerModel?: PartnerResultInterface;
  partners: PartnerData[];
  partnersSelectData: SelectData[];
  partnerLoading: boolean;
  onDeletePartner: (id: string, params: GetAllPartnerParam) => void;
  getPartners: (params: GetAllPartnerParam) => void;
}

export const usePartnerStore = create<PartnerState>((set, get) => ({
  partnerLoading: false,
  partnerModel: undefined,
  partners: [],
  partnersSelectData: [],
  getPartners: async (params: GetAllPartnerParam) => {
    set({ partnerLoading: true });
    const response = await GetAllPartner(params);
    if (response) {
      if (response.status) {
        set({ partnerModel: response });
        if (response.data.data.length > 0) {
          let transformPartners: PartnerData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformPartners.push({
              id: item.id,
              name: item.name,
              media: item.media
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ partners: transformPartners, partnersSelectData: transformSelectDatas });
        }
      }
    }
    set({ partnerLoading: false });
  },
  onDeletePartner: async (id: string, params: GetAllPartnerParam) => {
    const response = await DeletePartner(id);
    if (response.status) {
      get().getPartners(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface PartnerFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: PartnerSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const usePartnerFormStore = create<PartnerFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowPartner(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: PartnerData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowPartner(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Image',
        placeholder: 'Image...',
        type: 'file-upload-v2',
        name: 'media',
        value: data?.media,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      }
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: PartnerSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitPartner(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdatePartner(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
