import React from "react";
import { CSSProperties } from "react";

interface CardProps {
  style?: CSSProperties;
  children?: React.ReactNode;
}

interface CardHeaderProps {
  title?: string;
  children?: React.ReactNode;
}

interface CardBodyProps {
  clean?: boolean;
  children?: React.ReactNode;
}

interface CardFooterProps {
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ style, children }) => {
  return (
    <div className="main-card mb-3 card" style={style}>
      {children}
    </div>
  );
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, children }) => {
  return (
    <div className="card-header-tab card-header">
      <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
        <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
        {title}
      </div>
      {children}
    </div>
  );
}

const CadBodyWithRef = React.forwardRef<HTMLDivElement, CardBodyProps>((props, ref) => {
  if (props.clean) {
    return <div ref={ref}>{props.children}</div>
  }

  return (
    <div ref={ref} className="card-body">
      {props.children}
    </div>
  );
})

const CardBody: React.FC<CardBodyProps> = ({ clean = false, children }) => {

  if (clean) {
    return <div>{children}</div>
  }

  return (
    <div className="card-body">
      {children}
    </div>
  );
}

const CardFooter: React.FC<CardFooterProps> = ({ children }) => {
  return (
    <div className="d-block text-right card-footer">
      {children}
    </div>
  );
}

export {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CadBodyWithRef
};