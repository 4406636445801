import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { ProductData, ProductResultInterface } from '../interfaces/product-result.interface';
import { ProductSingleResultInterface } from '../interfaces/product-single-result.interface';
import { DeleteProduct, GetAllProduct, GetAllProductParam, ShowProduct, SubmitProduct, UpdateProduct } from '../providers/product.provider';

interface ProductState {
  productModel?: ProductResultInterface;
  products: ProductData[];
  productsSelectData: SelectData[];
  productLoading: boolean;
  onDeleteProduct: (id: string, params: GetAllProductParam) => void;
  getProducts: (params: GetAllProductParam) => void;
  searchForms: FormInput[];
  productParams: GetAllProductParam;
  setProductParams: (data: GetAllProductParam) => void;
  setSearchForms: (index: number, value: any) => void;
  setSearchFormSelectData: (index: number, selectData: SelectData[]) => void;
}

export const useProductStore = create<ProductState>((set, get) => ({
  productLoading: false,
  productModel: undefined,
  products: [],
  productsSelectData: [],
  setProductParams: (data: GetAllProductParam) => {
    set({ productParams: data });
  },
  productParams: {
    page: 0,
    limit: 10
  },
  searchForms: [
    {
      title: 'Product Name',
      placeholder: 'Product Name...',
      type: 'text',
      name: 'name',
      value: '',
      disabled: false,
    },
    {
      title: 'Brand',
      placeholder: 'Brand...',
      type: 'select-with-text',
      name: 'brandId',
      selectData: [],
      value: {
        label: 'Choose brand',
        value: '',
      },
      disabled: false
    },
  ],
  setSearchForms: (index: number, value: any) => {
    const forms = [...get().searchForms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ searchForms: forms });
  },
  setSearchFormSelectData: (index: number, selectData: SelectData[]) => {
    const newSelectData: SelectData[] = [
      {
        label: 'Choose brand...',
        value: ''
      }
    ];
    newSelectData.push(...selectData);
    const forms = [...get().searchForms];
    forms[index] = {
      ...forms[index],
      selectData: newSelectData,
    }
    set({ searchForms: forms });
  },
  getProducts: async (params: GetAllProductParam) => {
    set({ productLoading: true });
    const response = await GetAllProduct(params);
    if (response) {
      if (response.status) {
        set({ productModel: response });
        if (response.data.data.length > 0) {
          let transformProducts: ProductData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformProducts.push({
              id: item.id,
              name: item.name,
              // slug: item.slug,
              brandName: item.brand.name,
              media: item.medias.length > 0 ? item.medias[0] : "9961f37f-63b5-4506-b972-872f0f272b07"
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ products: transformProducts, productsSelectData: transformSelectDatas });
        } else {
          set({ products: [], productsSelectData: [] });
        }
      } else {
        set({ products: [], productsSelectData: [] });
      }
    } else {
      set({ products: [], productsSelectData: [] });
    }
    set({ productLoading: false });
  },
  onDeleteProduct: async (id: string, params: GetAllProductParam) => {
    const response = await DeleteProduct(id);
    if (response.status) {
      get().getProducts(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  },
}));

interface ProductFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: ProductSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useProductFormStore = create<ProductFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowProduct(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: ProductData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowProduct(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    const test = data?.productCategories?.map((v, k) => {
      return {
        value: v.id,
        label: v.category?.name
      } as SelectData;
    });
    console.log(test);

    forms = [
      {
        title: 'Product Photo',
        placeholder: 'Product Photo...',
        type: 'file-upload-multi',
        name: 'medias',
        value: data?.medias ?? [],
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Description',
        placeholder: 'Description...',
        type: 'rich-text',
        name: 'description',
        value: data?.description ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Price',
        placeholder: 'Price...',
        type: 'number',
        name: 'price',
        value: data?.price ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Brand',
        placeholder: 'Brand...',
        type: 'select-with-text',
        name: 'brandId',
        selectData: [],
        value: {
          label: data?.brand?.name,
          value: data?.brand?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Categories',
        placeholder: 'Categories...',
        type: 'select-with-text-multi',
        name: 'productCategoryIds',
        selectData: [],
        value: data?.productCategories?.map((v, k) => {
          return {
            value: v.id,
            label: v.category?.name
          } as SelectData;
        }),
        isMulti: true,
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Tokopedia Link',
        placeholder: 'Tokopedia Link...',
        type: 'text',
        name: 'tokopediaLink',
        value: data?.tokopediaLink ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
      {
        title: 'Shopee Link',
        placeholder: 'Shopee Link...',
        type: 'text',
        name: 'shopeeLink',
        value: data?.shopeeLink ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // let formData = {};
    // for (const v of get().forms) {
    //   formData = {
    //     ...formData,
    //     [v.name]: generateFormLogic(v.type, v?.value ?? ''),
    //   };
    // }
    // console.log(formData);
    // return;
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: ProductSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitProduct(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateProduct(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
