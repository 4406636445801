import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Outlet, useNavigate } from "react-router-dom";
import { FormAction, FormProps } from "../../../interfaces/form-action-interfaces";
import { Card, CardHeader, CardBody, CardFooter } from "../../forms/components/card";
import { PageTitle } from "../../forms/components/page-title";
import { Table } from "../../forms/components/table";
import { BrandData } from "../interfaces/brand-result.interface";
import { GetAllBrandParam } from "../providers/brand.provider";
import { useBrandStore } from "../states/brand.state";

export const BrandLayout = () => {
  return (
    <>
      <PageTitle
        title={'Brand'}
        subtitle={'This is an example dashboard created using build-in elements and components.'}
      />
      <Outlet/>
    </>
  );
}

export const BrandScreen = () => {

  const { brands, getBrands, brandModel, onDeleteBrand } = useBrandStore();
  const [brandParams, setBrandParams] = useState<GetAllBrandParam>({ page: 0, limit: 10 });
  const navigate = useNavigate();

  useEffect(() => {
    getBrands(brandParams);
  }, [brandParams])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected + 1;
    setBrandParams({ 
      ...brandParams,
      page: selectedPage
    })
  }

  const handleOnEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: BrandData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.UPDATE,
        title: 'Brand',
        subtitle: 'Form Brand',
        id: item.id,
      } as FormProps
    });
  }

  const handleOnView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: BrandData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.VIEW,
        title: 'Brand',
        subtitle: 'Form Brand',
        id: item.id
      } as FormProps
    });
  }

  return (
    <>
      <Card>
        <CardHeader title={'Brand'}>
          <div className="btn-actions-pane-right">
            <button 
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                navigate('form', {
                  state: {
                    action: FormAction.CREATE,
                    title: 'Brand',
                    subtitle: 'Form Brand'
                  } as FormProps
                });
              }}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </CardHeader>
        <CardBody clean={true}>
          <Table
            data={brands}
            actionButton={(index, item) => {
              let data: BrandData = item as BrandData;
              return (
                <div role={'group'} className="btn-group-sm btn-group btn-group-toggle">
                  {
                    <>
                      <button type="button" className="btn btn-primary" onClick={(e) => handleOnView(e, data)}>View</button>
                      <button type="button" className="btn btn-danger" onClick={e => onDeleteBrand(data.id, brandParams)}>Delete</button>
                      <button type="button" className="btn btn-warning" onClick={e => handleOnEdit(e, data)}>Edit</button>
                    </>
                  }
                </div>
              );
            }}
          />
        </CardBody>
        <CardFooter>
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={brandModel?.data?.meta?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </CardFooter>
      </Card>
    </>
  );
}