import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Outlet, useNavigate } from "react-router-dom";
import { FormAction, FormProps } from "../../../interfaces/form-action-interfaces";
import { Card, CardHeader, CardBody, CardFooter } from "../../forms/components/card";
import { PageTitle } from "../../forms/components/page-title";
import { Table } from "../../forms/components/table";
import { GensetGridData } from "../interfaces/genset-grid-result.interface";
import { useGensetGridStore } from "../states/genset-grid.state";

export const GensetGridLayout = () => {
  return (
    <>
      <PageTitle
        title={'GensetGrid'}
        subtitle={'This is an example dashboard created using build-in elements and components.'}
      />
      <Outlet/>
    </>
  );
}

export const GensetGridScreen = () => {

  const { gensetGrids, getGensetGrids, gensetGridModel, onDeleteGensetGrid } = useGensetGridStore();
  const navigate = useNavigate();

  useEffect(() => {
    getGensetGrids({ page: 0, limit: 10});
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected + 1;
    getGensetGrids({ page: selectedPage, limit: 10});
  }

  const handleOnEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: GensetGridData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.UPDATE,
        title: 'GensetGrid',
        subtitle: 'Form GensetGrid',
        id: item.id,
      } as FormProps
    });
  }

  const handleOnView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: GensetGridData) => {
    e.preventDefault();
    navigate('form', {
      state: {
        action: FormAction.VIEW,
        title: 'GensetGrid',
        subtitle: 'Form GensetGrid',
        id: item.id
      } as FormProps
    });
  }

  return (
    <>
      <Card>
        <CardHeader title={'GensetGrid'}>
          <div className="btn-actions-pane-right">
            <button 
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                navigate('form', {
                  state: {
                    action: FormAction.CREATE,
                    title: 'GensetGrid',
                    subtitle: 'Form GensetGrid'
                  } as FormProps
                });
              }}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </CardHeader>
        <CardBody clean={true}>
          <Table
            data={gensetGrids}
            actionButton={(index, item) => {
              let data: GensetGridData = item as GensetGridData;
              return (
                <div role={'group'} className="btn-group-sm btn-group btn-group-toggle">
                  {
                    <>
                      <button type="button" className="btn btn-primary" onClick={(e) => handleOnView(e, data)}>View</button>
                      <button type="button" className="btn btn-danger" onClick={e => onDeleteGensetGrid(data.id, { page: 0, limit: 10 })}>Delete</button>
                      <button type="button" className="btn btn-warning" onClick={e => handleOnEdit(e, data)}>Edit</button>
                    </>
                  }
                </div>
              );
            }}
          />
        </CardBody>
        <CardFooter>
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={gensetGridModel?.data?.meta?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </CardFooter>
      </Card>
    </>
  );
}