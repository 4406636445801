import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoPerbasi from '../../../assets/img/logo-inverse.jpeg';
import { useSidebarStore } from "../../../contexts/sidebar/sidebar.state";
import { useAuthStore } from "../../auth/states/auth.state";

const LogoImage = styled.img`
  width: 50px;
  text-align: center
`;

export const Header = () => {
  return (
    <div className="app-header header-shadow">
      <HeaderLogo/>
      <HeaderMobileMenu/>
      <HeaderMenu/>
      <HeaderContent/>
    </div>
  );
}

export const HeaderLogo = () => {

  const { isSidebarDesktopOpen, setIsSidebarDesktopOpen } = useSidebarStore();

  return (
    <div className="app-header__logo">
      <LogoImage
        src={LogoPerbasi}
        alt={"Logo Perbasi"}
      />
      <div className="header__pane ml-auto">
        <div>
          <button type="button" onClick={e => setIsSidebarDesktopOpen(!isSidebarDesktopOpen)} className={`hamburger close-sidebar-btn hamburger--elastic ${isSidebarDesktopOpen ? 'is-active' : ''}`} data-class="closed-sidebar">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export const HeaderMobileMenu = () => {

  const { isSidebarMobileOpen, setIsSidebarMobileOpen } = useSidebarStore();

  return (
    <div className="app-header__mobile-menu">
      <div>
        <button onClick={e => setIsSidebarMobileOpen(!isSidebarMobileOpen)} type="button" className={
          isSidebarMobileOpen ? "hamburger hamburger--elastic mobile-toggle-nav is-active" : "hamburger hamburger--elastic mobile-toggle-nav"
          }>
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  );
}

export const HeaderMenu = () => {

  const { isHeaderMobileOpen, setIsHeaderMobileOpen } = useSidebarStore();

  return (
    <div className="app-header__menu">
      <span>
        <button onClick={e => setIsHeaderMobileOpen(!isHeaderMobileOpen) }type="button" className={`btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav ${isHeaderMobileOpen ? 'active' : ''}`}>
          <span className="btn-icon-wrapper">
            <i className="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
  );
}

const HeaderContent = () => {

  const { isHeaderMobileOpen } = useSidebarStore();

  return (
    <div className={`app-header__content ${isHeaderMobileOpen ? 'header-mobile-open' : ''}`}>
      <HeaderContentRight/>
    </div>
  );
}

const HeaderContentRight = () => {

  const { getMe, meModel } = useAuthStore();

  useEffect(() => {
    getMe();
  }, [getMe]);

  return(
    <div className="app-header-right">
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
            <div className="widget-content-wrapper">
                <div className="widget-content-left">
                    <div className="btn-group">
                        <a href="#me" data-toggle="dropdown" aria-expanded="false" className="p-0 btn">
                          <img 
                            className="rounded-circle" 
                            src={`${process.env.REACT_APP_ASSETS_URL!}/${meModel?.data?.avatar}`} 
                            alt=""
                            style={{
                              width: 42,
                              height: 42,
                              objectFit: 'cover'
                            }}/>
                          <i className="fa fa-angle-down ml-2 opacity-8"></i>
                        </a>
                        <div tabIndex={-1} role="menu" aria-hidden="true" className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                            <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-info">
                                <div 
                                  className="menu-header-image opacity-2" 
                                  style={{
                                    backgroundImage: "url('/assets/images/dropdown-header/city3.jpg')"
                                  }}>    
                                </div>
                                <UserLogout/>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget-content-left  ml-3 header-user-info">
                    <div className="widget-heading"> {meModel?.data?.fullName}</div>
                    <div className="widget-subheading"> {meModel?.data?.email}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}

const UserLogout = () => {

  const { meModel, logout } = useAuthStore();

  return (
    <div className="menu-header-content text-left">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left mr-3">
            <img width="42" className="rounded-circle" src={`${process.env.REACT_APP_ASSETS_URL!}/${meModel?.data?.avatar}`} alt=""/>
          </div>
          <div className="widget-content-left">
            <div className="widget-heading">{meModel?.data?.fullName}</div>
            <div className="widget-subheading opacity-8">{meModel?.data?.email}</div>
          </div>
          <div className="widget-content-right mr-2">
            <Link
              type="a"
              to={"/"}
              onClick={e => {
                e.preventDefault();
                logout();
              }}
              className="btn-pill btn-shadow btn-shine btn btn-focus">
                Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}