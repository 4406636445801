import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProps } from "../../../interfaces/form-action-interfaces";
import { useBrandStore } from "../../brand/states/brand.state";
import { useCategoryStore } from "../../category/states/category.state";
import { Card, CardBody, CardFooter, CardHeader } from "../../forms/components/card";
import { RichText } from "../../forms/components/ckeditor/rich-text";
import { FileUploadMulti } from "../../forms/components/file-upload-multi";
import { FileUploadV2 } from "../../forms/components/file-upload-v2";
import FormsComponent from "../../forms/components/forms.component";
import { Input, SelectWithText } from "../../forms/components/input";
import { useRoleStore } from "../../roles/states/role.state";
import { useProductFormStore } from "../states/product.state";

const ProductFormScreen = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { formParam, setFormParam, forms, setForms, generateForms, onSubmitForm, submitDone, reset, setFormSelectData } = useProductFormStore();
  const { getBrands, brandsSelectData } = useBrandStore();
  const { getCategories, categoriesSelectData } = useCategoryStore();

  useEffect(() => {
    if (location) {
      setFormParam(location.state as FormProps);
      generateForms(location.state as FormProps);
      getBrands({ page: 0, limit: 100 });
      getCategories({ page: 0, limit: 100 });
    }
  }, [location]);

  useEffect(() => {
    if (brandsSelectData.length > 0) {
      setFormSelectData(4, brandsSelectData);
    }
  }, [brandsSelectData]);

  useEffect(() => {
    if (categoriesSelectData.length > 0) {
      setFormSelectData(5, categoriesSelectData);
    }
  }, [categoriesSelectData]);

  useEffect(() => {
    console.log(submitDone);
    if (submitDone) {
      reset();
      navigate(-1)
    }
  }, [submitDone])

  return (
    <form onSubmit={e => {
      e.preventDefault();
      onSubmitForm();
    }}>
      <Card>
        <CardHeader title={`${formParam?.action} ${formParam?.title}`}/>  
        <CardBody>
          <FormsComponent
            forms={forms}
            setForms={setForms}
          />
        </CardBody>
        <CardFooter>
          <button 
            type="submit" 
            className="btn btn-success btn-lg"
            onClick={(e) => {
              e.preventDefault();
              onSubmitForm();
            }}>
              Submit
          </button>
        </CardFooter>
      </Card>  
    </form>
  );
}

export default ProductFormScreen;