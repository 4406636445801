import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { ProductData, ProductResultInterface } from "../interfaces/product-result.interface";
import { ProductSingleResultInterface } from "../interfaces/product-single-result.interface";

export interface GetAllProductParam {
  page?: number,
  limit?: number,
  search?: string,
  'filter.brand.id'?: string,
}

export const GetAllProduct = async (params: GetAllProductParam): Promise<ProductResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/products',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProductResultInterface = a.response?.data;
    return response;
  }
}

export const ShowProduct = async (productId: string): Promise<ProductSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/products/' + productId,
    );
    return response.data as ProductSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProductSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitProduct = async (form: ProductData): Promise<ProductSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/products',
      form
    );
    return response.data as ProductSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProductSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateProduct = async (id: string, form: ProductData): Promise<ProductSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/products/' + id,
      form
    );
    return response.data as ProductSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProductSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteProduct = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/products/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}