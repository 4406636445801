interface ToggleProps {
  title: string;
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({ title, name, onChange, checked, disabled = false }) => {
  return(
    <div className="position-relative form-check">
      <label className="form-check-label">
        <input
          type="checkbox" 
          className="form-check-input"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {title}
      </label>
    </div>
  );
}