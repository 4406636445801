import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { RoleData, RoleResultInterface } from "../interfaces/role-result.interface";
import { RoleSingleResultInterface } from "../interfaces/role-single-result.interface";

export interface GetAllRoleParam {
  page?: number,
  limit?: number,
}

export const GetAllRole = async (params: GetAllRoleParam): Promise<RoleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/roles',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleResultInterface = a.response?.data;
    return response;
  }
}

export const ShowRole = async (roleId: string): Promise<RoleSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/roles/' + roleId,
    );
    return response.data as RoleSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitRole = async (form: RoleData): Promise<RoleSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/roles',
      form
    );
    return response.data as RoleSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateRole = async (id: string, form: RoleData): Promise<RoleSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/roles/' + id,
      form
    );
    return response.data as RoleSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteRole = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/roles/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}