import { toast } from 'react-toastify';
import create from 'zustand';
import { generateFormLogic } from '../../../helpers/generate-form-logic';
import { FormAction, FormInput, FormProps, SelectData } from '../../../interfaces/form-action-interfaces';
import { RoleData, RoleResultInterface } from '../interfaces/role-result.interface';
import { RoleSingleResultInterface } from '../interfaces/role-single-result.interface';
import { DeleteRole, GetAllRole, GetAllRoleParam, ShowRole, SubmitRole, UpdateRole } from '../providers/role.provider';

interface RoleState {
  roleModel?: RoleResultInterface;
  roles: RoleData[];
  rolesSelectData: SelectData[];
  roleLoading: boolean;
  onDeleteRole: (id: string, params: GetAllRoleParam) => void;
  getRoles: (params: GetAllRoleParam) => void;
}

export const useRoleStore = create<RoleState>((set, get) => ({
  roleLoading: false,
  roleModel: undefined,
  roles: [],
  rolesSelectData: [],
  getRoles: async (params: GetAllRoleParam) => {
    set({ roleLoading: true });
    const response = await GetAllRole(params);
    if (response) {
      if (response.status) {
        set({ roleModel: response });
        if (response.data.data.length > 0) {
          let transformRoles: RoleData[] = [];
          let transformSelectDatas: SelectData[] = [];
          for (const item of response.data.data) {
            transformRoles.push({
              id: item.id,
              name: item.name,
              slug: item.slug
            });
            transformSelectDatas.push({
              value: item.id,
              label: item.name
            });
          }
          set({ roles: transformRoles, rolesSelectData: transformSelectDatas });
        }
      }
    }
    set({ roleLoading: false });
  },
  onDeleteRole: async (id: string, params: GetAllRoleParam) => {
    const response = await DeleteRole(id);
    if (response.status) {
      get().getRoles(params);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));

interface RoleFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: RoleSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const formInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useRoleFormStore = create<RoleFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowRole(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
    return;
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam });
    
    let data: RoleData = undefined
    if (formParam.action === FormAction.VIEW || formParam.action === FormAction.UPDATE) {
      const response = await ShowRole(formParam.id);
      if (response.status) {
        data = response.data;
      }
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: data?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false
      }
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error('Cant submit form, because its read-only');
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ''),
        };
      }

      let res: RoleSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE) res = await SubmitRole(formData);
      if (get().formParam.action === FormAction.UPDATE) res = await UpdateRole(get().formParam.id, formData);

      console.log(res.status);
      if (res.status) {
        toast.success(res.message);
        set({ submitDone: true, formLoading: false });
        return;
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message);
        }
        set({ submitDone: false, formLoading: false });
        return;
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
      return;
    }
  },
  reset: () => {
    set(formInitialState);
    return;
  }
}));
