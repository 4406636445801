import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { EngineTypeResultInterface, EngineTypeData } from "../interfaces/engine-type-result.interface";
import { EngineTypeSingleResultInterface } from "../interfaces/engine-type-single-result.interface";

export interface GetAllEngineTypeParam {
  page?: number,
  limit?: number,
}

export const GetAllEngineType = async (params: GetAllEngineTypeParam): Promise<EngineTypeResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/engine-types',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: EngineTypeResultInterface = a.response?.data;
    return response;
  }
}

export const ShowEngineType = async (engineTypesId: string): Promise<EngineTypeSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/engine-types/' + engineTypesId,
    );
    return response.data as EngineTypeSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EngineTypeSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitEngineType = async (form: EngineTypeData): Promise<EngineTypeSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/engine-types',
      form
    );
    return response.data as EngineTypeSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EngineTypeSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateEngineType = async (id: string, form: EngineTypeData): Promise<EngineTypeSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/engine-types/' + id,
      form
    );
    return response.data as EngineTypeSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EngineTypeSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteEngineType = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/engine-types/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}