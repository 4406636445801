import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import LogoPerbasi from '../../../assets/img/logo-inverse.jpeg';
import { HOME, ROOT } from "../../../router/routes";
import { useAuthStore } from "../states/auth.state";

const LogoImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const LogoImage = styled.img`
  width: 250px;
  text-align: center
`;

function Login() {

  const { login, isLoggedIn } = useAuthStore();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROOT)
    }
  }, [isLoggedIn])
  
  return (
    <div className="app-container app-theme-white body-tabs-shadow">
      <div className="app-container">
        <div className="h-100">
          <div className="h-100 no-gutters row">
            <div className="d-none d-lg-block col-lg-4">
              <div className="slider-light">
                <div className="slick-slider">
                  <div>
                    <div className="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate" tabIndex={-1}>
                      <div className="slide-img-bg" style={{
                        backgroundImage: `url('/assets/images/originals/city.jpg')`
                      }}></div>
                      <div className="slider-content">
                        <h3>
                          {/* Persatuan Bola Basket Seluruh Indonesia */}
                        </h3>
                        <p>
                        {/* Persatuan Bola Basket Seluruh Indonesia atau akrabnya dikenal sebagai Perbasi adalah wadah untuk kegiatan olahraga bola basket nasional... */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
              <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
                {/* <div className="app-logo"></div> */}
                <LogoImageContainer>
                  <LogoImage
                    src={LogoPerbasi}
                    alt={'Logo Perbasi'}
                  />
                </LogoImageContainer>
                <div>

                </div>
                <h4 className="mb-0">
                  <span className="d-block">Welcome back,</span>
                  <span>Please sign in to your account.</span>
                </h4>
                {/* <h6 className="mt-3">No account?
                  <a href="/" className="text-primary">Sign up now</a>
                </h6> */}
                <div className="divider row"></div>
                <div>
                  <form className="">
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="position-relative form-group">
                          <label className="">Email</label>
                          <input 
                            name="email" 
                            id="exampleEmail" 
                            placeholder="Email here..." 
                            type="email" 
                            className="form-control"
                            onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="position-relative form-group">
                          <label className="">Password</label>
                          <input 
                            name="password" 
                            id="examplePassword" 
                            placeholder="Password here..." 
                            type="password" 
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                      </div>
                    </div>
                    <div className="position-relative form-check">
                      <input name="check" id="exampleCheck" type="checkbox" className="form-check-input"/>
                      <label className="form-check-label">Keep me logged in</label>
                    </div>
                    <div className="divider row"></div>
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        <a href="/" className="btn-lg btn btn-link">Recover Password</a>
                        <button 
                          className="btn btn-primary btn-lg"
                          onClick={(e) => {
                            e.preventDefault();
                            login(username, password);
                          }}>
                            Login to Dashboard
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );  
}

export default Login;