import { AxiosError } from "axios";
import { ApiAuthorized } from "../../../api/api";
import { GeneralInterface } from "../../../interfaces/general.interface";
import { SiteData, SiteResultInterface } from "../interfaces/site-result.interface";
import { SiteSingleResultInterface } from "../interfaces/site-single-result.interface";

export interface GetAllSiteParam {
  page?: number,
  limit?: number,
}

export const GetAllSite = async (params: GetAllSiteParam): Promise<SiteResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/sites',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: SiteResultInterface = a.response?.data;
    return response;
  }
}

export const ShowSite = async (roleId: string): Promise<SiteSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.get(
      '/sites/' + roleId,
    );
    return response.data as SiteSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: SiteSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitSite = async (form: SiteData): Promise<SiteSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.post(
      '/sites',
      form
    );
    return response.data as SiteSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: SiteSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateSite = async (id: string, form: SiteData): Promise<SiteSingleResultInterface> => {
  try {
    let response = await ApiAuthorized.patch(
      '/sites/' + id,
      form
    );
    return response.data as SiteSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: SiteSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteSite = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorized.delete(
      '/sites/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}